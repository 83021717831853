:root{
  --primary: #E40A2D;
  --background: #f6f6f6;
}

#root{
  height: 100%;
}

html{
  height: 100%;
}

body{
  background: var(--background);
  height: 100%;
}

h1, h2, h3{
  color: var(--primary);
  font-family: 'Mountains of Christmas', cursive;
}

h1{
  font-size: 48px;
  margin: 8px 0 8px;
}

h2{
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 40px;
}

h3{
  font-size: 28px;
  margin: 0;
}

a{
  text-decoration: none;
  color: var(--primary);
}

input{
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid var(--background);
  padding: 8px;
  box-shadow: inset var(--background) 4px 4px 8px;
  -webkit-appearance: none;
}

input:disabled{
  background-color: white;
}

button{
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  padding: 8px 16px 8px;
  float: right;
}

.error{
  color:red;
}

.label{
  margin: 0;
  font-weight: bold;
}

.navigation{
  position: sticky;
  background-color: white;
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
}

.card{
  background-color: white;
  border-radius: 16px;
  padding: 16px 32px 16px;
}

.inverted{
  background-color: white;
  color:  var(--primary);
}

.groupCard{
  background-color: var(--primary);
  border-radius: 16px;
  color: white;
  padding: 16px;
  width: 400px;
  max-width: 100%;
  margin: 16px;
}

.partecipante{
  margin: 8px;
  font-weight: bold;
}

.loadingScreen{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assolutamenteCentrato{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 550px) {
  h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 440px) {
  h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 370px) {
  .siteTitle {
    display: none;
  }
}